<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    @close="closeHandle"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item
          label="供应商名称"
          :label-width="'100px'"
          prop="supplierName"
        >
          <el-input
            v-model="dataForm.supplierName"
            placeholder="供应商名称"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>

        <el-form-item
          label="联系人"
          :label-width="'100px'"
          prop="supplierContract"
        >
          <el-input
            v-model="dataForm.supplierContract"
            placeholder="联系人"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>
        <el-form-item
          label="联系方式"
          :label-width="'100px'"
          prop="supplierPhone"
        >
          <el-input
            v-model="dataForm.supplierPhone"
            placeholder="联系方式"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>
        <el-form-item
          label="关联设备"
          :label-width="'100px'"
          prop="supplierCode"
        >
          <el-select
            v-model="dataForm.supplierCode"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
            @change="changeHandle()"
          >
            <el-option
              v-for="(item, index) in deviceList"
              :key="index"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        id: null,
        supplierName: null,
        organId: null,
        supplierContract: null,
        supplierPhone: null,
        abbreviation: null,
        enabled: 1,
        supplierCode: null,
      },
      visible: false,
      deviceList: null,
    };
  },
  computed: {
    rules() {
      return {
        supplierName: [
          { required: true, message: "必填项不能为空", trigger: "bulr" },
        ],
        supplierContract: [
          { required: true, message: "必填项不能为空", trigger: "bulr" },
        ],
        supplierPhone: [
          { required: true, message: "必填项不能为空", trigger: "bulr" },
          { validator: this.validatePhoneNumber, trigger: 'blur' }
        ],
        supplierCode: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getDeviceList();
        if(this.dataForm.id) {
          this.getDataInfo()
        }
      });
    },
    closeHandle() {
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        id: null,
        supplierName: null,
        organId: null,
        supplierContract: null,
        supplierPhone: null,
        abbreviation: null,
        enabled: 1,
        supplierCode: null,
      }
    },
    getDataInfo() {
      axios
        .post(`/api/supplier/supplierDetail`, {id: this.dataForm.id})
        .then((res) => {
          this.dataForm = {...res}
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    //获取关联设备列表
    getDeviceList() {
      axios
        .post("/api/supplier/list", {})
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    changeHandle() {
      this.deviceList.forEach(item => {
        if(item.code === this.dataForm.supplierCode) {
          this.dataForm.abbreviation = item.value
        }
      })
    },
    	validatePhoneNumber(rule, value, callback) {
				if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
					callback();
				} else {
					callback(new Error('请输入合法的手机号'));
				}
			},
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/supplier/addSupplier`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>