<template>
  <div class="supplier-management-container">
    <div class="supplier-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="供应商">
          <el-input
            v-model="formData.supplierName"
            placeholder="供应商"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="formData.enabled"
            placeholder="请选择"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
        <el-row>
          <el-form-item style="float: left; marginright: 0px">
            <el-button type="primary" @click="addData()">添加供应商</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="supplier-table-container">
      <div class="supplier-table-content">
        <el-table
          style="width: 100%"
          height="500px"
          v-loading="loading"
          :data="list"
        >
          <el-table-column label="供应商名称" prop="sn">
            <template slot-scope="scope">
              {{ scope.row.supplierName || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="联系人" prop="sender">
            <template slot-scope="scope">
              {{ scope.row.supplierContract || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="联系方式" prop="sendTime">
            <template slot-scope="scope">
              {{ scope.row.supplierPhone || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="添加时间" prop="status">
            <template slot-scope="scope">
              {{ scope.row.createTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="infoHandle(scope.row)" type="text"
                >修改</el-button
              >
              <el-button
                @click="setHandle(scope.row)"
                type="text"
                >操作</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="supplier-table-page">
        <div class="supplier-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <AddStore v-if="visable" ref="addOrUpdate" @refreshDataList="getData"/>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import "./Index.less";
import moment from "moment";
import AddStore from './components/addStore.vue'
export default {
  name: "SupplierManagement",
  components: { AddStore },
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      formData: {
        supplierName: null,
        enabled: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: null,
      },
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.getData();
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/supplier/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        supplierName: null,
        enabled: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: localStorage.getItem("organId"),
      };
      this.getData();
    },
    addData() {
      this.visable = true
      this.$nextTick(() => {
				this.$refs.addOrUpdate.init()
        this.$refs.addOrUpdate.title = '新增供应商'
			});
    },
    infoHandle(row) {
      this.visable = true
      this.$nextTick(() => {
				this.$refs.addOrUpdate.init()
        this.$refs.addOrUpdate.title = '修改供应商'
        this.$refs.addOrUpdate.dataForm.id = row.id
			});
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    setHandle(row) {
      this.$router.push({
        name: "SupplierOperation",
        params: {
          id: row.id,
        },
      });
    },
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
};
</script>